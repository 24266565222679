import { Button, Input } from '@ion/design-system';
import { signIn } from '@ion/next-auth/react';
import { Label } from '@radix-ui/react-label';
import { useState } from 'react';
import { z } from 'zod';

import CenteredCard from '@/components/centered-card';
import Layout from '@/components/layout';
import { cn } from '@/lib/utils';

import { Icons } from '../icons';
import Spinner from '../ui/spinner';

export default function SignIn() {
  const [email, setEmail] = useState('');
  const [emailLoading, setEmailLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [githubLoading, setGithubLoading] = useState(false);
  return (
    <Layout>
      <CenteredCard className="sm:w-[350px]" bordered>
        <div className="mx-auto flex w-full flex-col justify-center space-y-6">
          <h1 className="ion-title">ion design</h1>
          <div className="grid gap-1">
            <div className="text-secondary mb-1 text-base">Sign in with email</div>
            <Label className="sr-only" htmlFor="email">
              Email
            </Label>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              placeholder="name@example.com"
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={emailLoading}
            />
            <Button
              disabled={z.string().email().safeParse(email).success === false || emailLoading}
              className="mt-2 w-full transition-all"
              onClick={() => {
                try {
                  void signIn('email', {
                    email,
                  });
                  setEmailLoading(true);
                } catch (e) {
                  setEmailLoading(false);
                }
              }}
              iconLeading={emailLoading ? <Spinner size="sm" /> : undefined}
            >
              Sign In
            </Button>
          </div>
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t" />
            </div>
            <div className="relative flex justify-center text-xs lowercase">
              <span className="bg-background text-secondary px-2">or continue with</span>
            </div>
          </div>
          <div className={cn('grid gap-6')}>
            <div className="grid gap-2">
              <Button
                type="button"
                variant={'outline'}
                className="w-full"
                disabled={githubLoading}
                onClick={() => {
                  try {
                    void signIn('github');
                    setGithubLoading(true);
                  } catch (e) {
                    setGithubLoading(false);
                  }
                }}
                iconLeading={githubLoading ? <Spinner size="sm" /> : <Icons.gitHub className="w-4 h-4" />}
              >
                Github
              </Button>
              <Button
                type="button"
                variant={'soft'}
                className="w-full"
                disabled={googleLoading}
                onClick={() => {
                  try {
                    void signIn('google');
                    setGoogleLoading(true);
                  } catch (e) {
                    setGoogleLoading(false);
                  }
                }}
                iconLeading={googleLoading ? <Spinner size="sm" /> : <Icons.google className="w-4 h-4" />}
              >
                Google
              </Button>
            </div>
          </div>
          {/* <p className="text-left text-sm text-secondary">
            By clicking continue, you agree to our{' '}
            <Link
              href="https://www.copykitten.ai/terms"
              rel="noopener noreferrer"
              className="underline underline-offset-4 hover:text-primary"
            >
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link
              href="https://www.copykitten.ai/privacy"
              rel="noopener noreferrer"
              className="underline underline-offset-4 hover:text-primary"
            >
              Privacy Policy
            </Link>
            .
          </p>  */}
        </div>
      </CenteredCard>
    </Layout>
  );
}
