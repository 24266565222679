import { PostHog } from 'posthog-node';

import { env } from './env';

const posthog = new PostHog(env.NEXT_PUBLIC_POSTHOG_KEY, {
  host: env.NEXT_PUBLIC_POSTHOG_HOST,
});

export const identify = (user: { id: string; email?: string | null | undefined; name?: string | null | undefined }) => {
  posthog.debug(false);
  posthog.identify({
    distinctId: user.id,
    properties: {
      email: user.email,
      name: user.name,
    },
  });
};

export function trackEvent(event: PosthogEvents, user: { id: string } | null, properties?: object) {
  if (process.env.NODE_ENV === 'test') return;
  posthog.debug(process.env.NODE_ENV === 'production');
  posthog.capture({
    distinctId: user ? user.id : 'unknown',
    event: event,
    properties: { ...user, ...properties },
  });

  console.log(`POSTHOG TRIGGERED: ${event}`);
}

export enum PosthogEvents {
  LOGGED_IN = 'event: logged in',
  CREATED_ACCOUNT = 'event: created account',
  ADDED_FILE = 'event: added file',
  REMOVED_USER_FROM_FILE = 'event: removed user from file',
  ADDED_USER_TO_FILE = 'event: added user to file',

  SET_FRAMEWORK = 'event: set framework',

  PROVIDED_FEEDBACK = 'event: provided feedback',
  INITIATED_GENERATION = 'event: initiated generation',
  GENERATED_COMPONENT = 'event: generated component',
  INITIATED_PULL_CHANGES = 'event: initiated pull changes',
  PULLED_CHANGES = 'event: pulled changes',

  SEND_WELCOME_EMAIL = 'event: sent welcome email',

  HIT_ONBOARDING_URL = 'event: hit onboarding url',
  CONNECTED = 'event: connected to vscode',

  INITIATED_GENERATION_FROM_PROMPT = 'event: initiated generation from prompt',
  GENERATED_COMPONENT_FROM_PROMPT = 'event: generated from prompt',

  INITIATED_GENERATION_FROM_WEB = 'Initiated generation from web',
  GENERATED_COMPONENT_FROM_WEB = 'Generated from web',

  INITIATED_GENERATION_FROM_ONBOARDING = 'Initiated generation from web - onboarding ',
  GENERATED_COMPONENT_FROM_ONBOARDING = 'Generated from web - onboarding',

  CLICKED_USE_ONBOARDING_SAMPLE = 'Clicked use onboarding sample',
  CLICKED_USE_OWN_FIGMA_LINK_FROM_ONBOARDING = 'Clicked use own Figma link',

  CLICKED_DSLINT_PLUGIN_LINK = 'Clicked DSLint plugin link',
  CLICKED_VSCODE_MARKETPLACE_LINK = 'Clicked VS Code marketplace link',

  VIEWED_GENERATION_FROM_ONBOARDING = 'Viewed generation from onboarding',
  VIEWED_GENERATION_FROM_WEB = 'Viewed generation from web generation',

  VIEWED_CODE_FROM_ONBOARDING = 'Viewed code from onboarding',
  VIEWED_CODE_FROM_WEB = 'Viewed code from web generation',

  EXPORTED_CODE_FROM_WEB = 'Exported code from web generation',
  EXPORTED_CODE_FROM_ONBOARDING = 'Exported code from onboarding',

  COPIED_CODE_FROM_WEB = 'Copied code from web generation',
  COPIED_CODE_FROM_ONBOARDING = 'Copied code from onboarding',

  SUBSCRIBED = 'event: new user subscribed',
}
// Track generation and where it was from
// Track view of generation
// Track view code
// Track setup vs code

// Track new generation button and where it was from
