import { type DirectoryNode, type FileNode, type FileSystemTree } from '@webcontainer/api';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

const isTsxFile = (fileName: string): boolean => {
  return fileName.endsWith('.tsx');
};

const isDirectoryNode = (node: any): node is DirectoryNode => {
  return 'directory' in node;
};

const isFileNode = (node: any): node is FileNode => {
  return 'file' in node;
};

const toCamelCase = (str: string): string => {
  return str
    .replace('.tsx', '')
    .replace(/[-_.](\w)/g, (_, c) => c.toUpperCase())
    .replace(/^\w/, (c) => c.toUpperCase());
};

export type TsxFile = {
  componentNames?: string[];
  path: string;
  content: string;
};

export const processFileTreeToTsxFiles = (tree: FileSystemTree, currentPath: string = ''): TsxFile[] => {
  const results: TsxFile[] = [];

  Object.entries(tree).forEach(([name, node]) => {
    const newPath = currentPath ? `${currentPath}/${name}` : name;

    if (isDirectoryNode(node)) {
      results.push(...processFileTreeToTsxFiles(node.directory, newPath));
    } else if (isFileNode(node) && isTsxFile(name)) {
      const content =
        typeof node.file.contents === 'string' ? node.file.contents : new TextDecoder().decode(node.file.contents);

      results.push({
        componentNames: [name],
        path: newPath,
        content,
      });
    }
  });

  return results;
};

export const getGlobalsCssFromFileTree = (tree: FileSystemTree): string => {
  const results: string[] = [];

  Object.entries(tree).forEach(([name, node]) => {
    if (isDirectoryNode(node)) {
      results.push(...getGlobalsCssFromFileTree(node.directory));
    } else if (isFileNode(node) && name === 'globals.css') {
      const content =
        typeof node.file.contents === 'string' ? node.file.contents : new TextDecoder().decode(node.file.contents);

      results.push(content);
    }
  });

  return results.join('\n');
};

export const getTailwindConfigFromFileTree = (tree: FileSystemTree): string => {
  const results: string[] = [];

  Object.entries(tree).forEach(([name, node]) => {
    if (isDirectoryNode(node)) {
      results.push(...getTailwindConfigFromFileTree(node.directory));
    } else if (isFileNode(node) && (name === 'tailwind.config.js' || name === 'tailwind.config.ts')) {
      const content =
        typeof node.file.contents === 'string' ? node.file.contents : new TextDecoder().decode(node.file.contents);

      results.push(content);
    }
  });

  return results.join('\n');
};
