import { cn } from '@/lib/utils';

import { ToggleTheme } from './toggle-theme';

interface Props {
  children: React.ReactNode[] | React.ReactNode;
  bordered?: boolean;
  className?: string;
}

export default function CenteredCard({ children, bordered = false, className }: Props) {
  return (
    <div className={cn('relative flex h-full w-full items-center justify-center bg-inherit')}>
      <div
        className={cn(
          'bg-background relative max-w-[500px] w-full p-8 sm:min-w-[400px] sm:rounded-xl py-12',
          className
        )}
      >
        <div className="absolute right-6">
          <ToggleTheme />
        </div>
        {children}
      </div>
    </div>
  );
}
