import { cn } from '@/lib/utils';

import { Icons } from '../icons';

interface Props {
  size?: 'sm' | 'default' | 'lg';
  className?: string;
}

function Spinner({ size = 'default', className }: Props) {
  return (
    <Icons.spinner
      className={cn(
        'animate-spin',
        {
          'h-6 w-6': true,
          'h-4 w-4': size === 'sm',
          'h-8 w-8': size === 'lg',
        },
        className
      )}
    />
  );
}

export default Spinner;
