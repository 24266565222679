import { identify } from '@ion/api/posthog';
import { useSession } from '@ion/next-auth/react';
import { useEffect } from 'react';

import Spinner from '@/components/ui/spinner';

import SignIn from './signin';

function Auth({ children }: { children: React.ReactNode[] | React.ReactNode }) {
  const { status, data } = useSession();

  useEffect(() => {
    if (data?.user) {
      const lastIdentified = localStorage.getItem('posthog-identified');

      if (lastIdentified && new Date(lastIdentified).getTime() > Date.now() - 1000 * 60 * 60 * 24 * 7) return;

      identify(data.user);
      localStorage.setItem('posthog-identified', new Date().toISOString());
    }
  }, [data?.user]);

  if (status === 'loading') {
    return (
      <div className="flex h-screen w-screen flex-col items-center justify-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (status === 'authenticated') {
    return <>{children}</>;
  }

  return <SignIn />;
}

export default Auth;
